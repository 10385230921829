import { useAuth0 } from '@auth0/auth0-react';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FaSpinner } from 'react-icons/fa';

import { brandCarEnums, brandMotoEnums } from './brands';
import Button from '../../components/Button';
import FuzzyInput from '../../components/FuzzyInput';
import Select from '../../components/Select';
import useClickOutside from '../../hooks/useClickOutside';
import { CarWithPrice } from '../../types/types';
import { getModelsByBrand } from './services';
import {
  editTransaction,
  editVehicle,
  editBate,
} from '../../services/services';

const carLabelDictionary: { [key: string]: string } = {
  plate: 'Matrícula',
  frameNumber: 'Número de bastidor',
  enrollmentDate: 'Fecha de 1ᵃ Matriculación',
  fuel: 'Combustible',
  cc: 'Cubicaje (cc)',
  kms: 'Kilómetros',
  price: 'Precio',
};

const fuelLabelDictionary: { [key: string]: string } = {
  G: 'Gasolina',
  D: 'Diesel',
  Elc: 'Eléctrico',
  DyE: 'Híbrido Diesel y Eléctrico',
  GyE: 'Híbrido Gasolina y Eléctrico',
  S: 'Gasolina GLP',
  M: 'Etanol + Gasolina/Bio',
  H: 'Hidrógeno',
};

const errorsDictionary: { [key: string]: string } = {
  plate: 'La matrícula no puede contener espacios ni guiones',
};

export type Fuel =
  | 'Gasolina'
  | 'Diesel'
  | 'Eléctrico'
  | 'Híbrido Diesel y Eléctrico'
  | 'Híbrido Gasolina y Eléctrico'
  | 'Gasolina GLP'
  | 'Etanol + Gasolina/Bio'
  | 'Hidrógeno';

export interface CarModel {
  [key: string]: string;
  brand: string;
  model: string;
  cc: string;
  cylinders: string;
  fuel: string;
  kw: string;
  cvf: string;
  value: string;
}

type EditVehicleModalProps = {
  code: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  preloadedValue: CarWithPrice;
  onSave: () => void;
  procedureType: 'BATE' | 'TRANSACTION' | 'REGISTRATION';
};

function EditVehicleModal({
  code,
  isOpen,
  setIsOpen,
  onSave,
  preloadedValue,
  procedureType,
}: EditVehicleModalProps) {
  const modalRef = useRef();

  useClickOutside(modalRef, () => {
    setIsOpen(false);
  });

  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [editableCarInfo, setEditableCarInfo] = useState<Partial<CarWithPrice>>(
    {},
  );
  const [models, setModels] = useState<CarModel[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      if (editableCarInfo.brand) {
        const fetchedModels = await getModelsByBrand(editableCarInfo.brand);
        setModels(fetchedModels?.cars || []);
      }
    })();
  }, [editableCarInfo.brand]);

  const editTramit = useCallback(
    (
      token: string,
      code: string,
      payload: {
        priceContractValue: number;
      },
    ) => {
      switch (procedureType) {
        case 'TRANSACTION':
          return editTransaction(token, code, payload);
        case 'BATE':
          return editBate(token, code, payload);
        default:
          return null;
      }
    },
    [procedureType],
  );

  useEffect(() => {
    if (preloadedValue) {
      setEditableCarInfo({
        brand: preloadedValue.brand,
        model: preloadedValue.model,
        plate: preloadedValue.plate,
        frameNumber: preloadedValue.frameNumber,
        enrollmentDate: preloadedValue.enrollmentDate,
        fuel: preloadedValue.fuel,
        isMoto: preloadedValue.isMoto,
        cc: preloadedValue.cc,
        kms: preloadedValue.kms,
        price: preloadedValue.price,
      });
    }
  }, [preloadedValue]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setSubmitError(false);
    try {
      const token = await getAccessTokenSilently();
      await Promise.all(
        [
          editVehicle(token, {
            ...preloadedValue,
            ...editableCarInfo,
          }),
          editTramit(token, code, {
            priceContractValue: editableCarInfo.price,
          }),
        ].filter(Boolean),
      );
      onSave();
    } catch {
      setSubmitError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'plate' && (value.includes(' ') || value.includes('-'))) {
      setErrors(Array.from(new Set([...errors, 'plate'])));
    }
    if (name === 'plate' && !value.includes(' ') && !value.includes('-')) {
      setErrors(errors.filter((error) => error !== 'plate'));
    }
    if (name === 'enrollmentDate') {
      try {
        value = new Date(value).toISOString();
      } catch {
        return;
      }
    }

    setEditableCarInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getSafeDate = (date: string): string => {
    if (!date) return '';
    try {
      return new Date(date).toISOString().split('T')[0];
    } catch {
      return '';
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black/75 flex items-center justify-center z-50">
      <div ref={modalRef} className="p-10 rounded-md shadow-md bg-white w-3/4">
        <h3 className="text-xl mb-3">Vehículo</h3>
        <div className="w-1/3">
          <Select<string>
            values={[
              { value: 'car', label: 'Coche' },
              { value: 'moto', label: 'Moto' },
            ]}
            selected={{
              label: editableCarInfo.isMoto ? 'Moto' : 'Coche',
              value: editableCarInfo.isMoto ? 'moto' : 'car',
            }}
            setSelected={(e) =>
              setEditableCarInfo((prevState) => ({
                ...prevState,
                isMoto: e.value === 'moto',
              }))
            }
          />
        </div>
        <div className="relative my-4">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-500">
              Información del vehículo
            </span>
          </div>
        </div>
        <div className="flex gap-4 w-full items-center mb-6">
          <div className="w-1/2" data-testid="select-brand">
            <label
              htmlFor="brand"
              className="block text-sm font-medium text-gray-700"
            >
              Marca
            </label>
            <Select<string>
              values={
                editableCarInfo.isMoto
                  ? brandMotoEnums.map((e) => ({ value: e, label: e }))
                  : brandCarEnums.map((e) => ({ value: e, label: e }))
              }
              selected={{
                label: editableCarInfo.brand,
                value: editableCarInfo.brand,
              }}
              setSelected={(e) =>
                setEditableCarInfo((prevState) => ({
                  ...prevState,
                  brand: e.value,
                }))
              }
            />
          </div>
          <div className="w-1/2 z-20" data-testid="select-model">
            <label
              htmlFor="model"
              className="block text-sm font-medium text-gray-700"
            >
              Modelo
            </label>
            {editableCarInfo.isMoto ? (
              <div className="mt-1">
                <input
                  value={editableCarInfo.model}
                  onChange={handleChange}
                  type="text"
                  name="model"
                  id="model"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
            ) : (
              <FuzzyInput<CarModel>
                values={models}
                label="model"
                prefilledValue={preloadedValue.model}
                onSelectedChange={(e) =>
                  setEditableCarInfo((prevState) => ({
                    ...prevState,
                    model: e,
                  }))
                }
              />
            )}
          </div>
        </div>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
          {Object.keys(editableCarInfo)
            .filter((e) => !['model', 'brand', 'isMoto'].includes(e))
            .filter((e) => (e === 'cc' ? editableCarInfo.isMoto : true))
            .map((e: keyof CarWithPrice) => {
              const isEnrollmentDate = e === 'enrollmentDate';
              const inputValue = isEnrollmentDate
                ? getSafeDate(editableCarInfo.enrollmentDate)
                : (editableCarInfo[e] as string);
              return (
                <div className="sm:col-span-2 lg:col-span-1" key={e}>
                  <div>
                    {!['fuel', 'cc', 'kms', 'price'].includes(e) && (
                      <>
                        <label
                          htmlFor={e}
                          className="block text-sm font-medium text-gray-700"
                        >
                          {carLabelDictionary[e]}
                        </label>
                        <div className="mt-1">
                          <input
                            value={inputValue}
                            onChange={handleChange}
                            type={isEnrollmentDate ? 'date' : 'text'}
                            name={e}
                            id={e}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </>
                    )}
                    {(e === 'cc' || e === 'kms') && (
                      <>
                        <label
                          htmlFor={e}
                          className="block text-sm font-medium text-gray-700"
                        >
                          {carLabelDictionary[e]}
                        </label>
                        <div className="mt-1">
                          <input
                            value={inputValue}
                            onChange={handleChange}
                            type="number"
                            name={e}
                            id={e}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </>
                    )}
                    {e === 'price' && (
                      <>
                        <label
                          htmlFor={e}
                          className="block text-sm font-medium text-gray-700"
                        >
                          {carLabelDictionary[e]}
                        </label>
                        <div className="mt-1">
                          <input
                            value={inputValue}
                            onChange={handleChange}
                            type="number"
                            name={e}
                            id={e}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </>
                    )}
                    {e === 'fuel' && (
                      <>
                        <label
                          htmlFor={e}
                          className="block text-sm font-medium text-gray-700"
                        >
                          {carLabelDictionary[e]}
                        </label>
                        <Select<Fuel>
                          selected={{
                            label: fuelLabelDictionary[editableCarInfo.fuel],
                            value: fuelLabelDictionary[
                              editableCarInfo.fuel
                            ] as Fuel,
                          }}
                          setSelected={(el) =>
                            setEditableCarInfo((prevState) => ({
                              ...prevState,
                              fuel: el.value,
                            }))
                          }
                          values={Object.keys(fuelLabelDictionary).map(
                            (el) => ({
                              label: fuelLabelDictionary[el as Fuel],
                              value: el as Fuel,
                            }),
                          )}
                        />
                      </>
                    )}
                    {errors.includes(e) && (
                      <p className="mt-2 -mb-6 text-xs ml-1 text-red-600">
                        {errorsDictionary[e]}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
        </dl>
        <div className="flex w-full justify-end mt-6">
          {isLoading && (
            <Button
              bgColor="bg-gray-200"
              hoverBgColor="bg-gray-200"
              textColor="white"
              text="Cargando..."
              RightIcon={FaSpinner}
              callback={handleSubmit}
              disabled
            />
          )}
          {!isLoading && !submitError && (
            <Button
              bgColor="bg-blue-700"
              hoverBgColor="bg-blue-900"
              textColor="white"
              text="Guardar"
              RightIcon={CheckIcon}
              callback={handleSubmit}
              disabled={errors.length > 0}
            />
          )}
          {!isLoading && submitError && (
            <Button
              bgColor="bg-red-700"
              hoverBgColor="bg-red-700"
              textColor="white"
              text="Error guardando los cambios"
              RightIcon={ExclamationIcon}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditVehicleModal;
