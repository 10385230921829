import React, { useContext, useEffect, useState } from 'react';
import { MdArrowBack, MdOutlineWarningAmber, MdSend } from 'react-icons/md';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';

import RecipientsTabGroup, {
  RecipientRolesTabGroupOptions,
} from './components/RecipientsTabGroup';
import RecipientItem, {
  AvailableRecipientRoles,
} from './components/RecipientItem';
import {
  BateTramit,
  RegistrationTramit,
  TemplateType,
  Transfer,
  User,
} from '../../../../types/types';
import { isRegistrationTramit } from '../../../../types/RegistrationTramit';
import { userInfoContext } from '../../../../context/UserInfoContext/UserInfoContext';
import { digitallySignContext } from '../../../../context/DigitallySignContext/DigitallySignContext';
import { DigitallySignSteps } from '../DigitallySignStepsManager';
import { DocumentSourceOptions } from '../ChooseDocumentSourceStep/ChooseDocumentSourceStep';
import { isBateTramit } from '../../../../types/BateTramit';
import { isTransfer } from '../../../../types/Transfer';
import { getRecipient } from '../../types/Recipient';

const hasCommunicationMethod = (recipient: User) => {
  if (!recipient) {
    return false;
  }

  return recipient.email?.length > 0 || recipient.phone?.length > 0;
};

export default function ChooseRecipientsStep({
  procedure,
  hide,
  templateType,
  forceUpdate,
}: {
  readonly procedure: Transfer | BateTramit | RegistrationTramit;
  readonly hide: () => void;
  readonly templateType: TemplateType;
  readonly forceUpdate: () => void;
}) {
  const [selectedRecipientRole, setSelectedRecipientRole] =
    useState<RecipientRolesTabGroupOptions>(
      RecipientRolesTabGroupOptions.BUYER,
    );
  const [isSendingDocument, setIsSendingDocument] = useState(false);

  const {
    setCurrentDigitallySignStep,
    chosenDocumentSource,
    documentPDF,
    buyerSendingMethod,
    sellerSendingMethod,
    setBuyerSendingMethod,
    setSellerSendingMethod,
    isMotoOcasionAccount,
  } = useContext(digitallySignContext);
  const { accountInfo } = useContext(userInfoContext);
  const isMotoOcasion = isMotoOcasionAccount(accountInfo?.organizationId);

  const { getAccessTokenSilently } = useAuth0();

  const [localBuyer, setLocalBuyer] = useState<User>(null);
  const [localSeller, setLocalSeller] = useState<User>(null);

  useEffect(() => {
    setLocalBuyer(getRecipient(procedure.buyer));
    if (isTransfer(procedure) || isBateTramit(procedure)) {
      setLocalSeller(getRecipient(procedure.seller));
    }
  }, []);

  const handleSendDocument = async () => {
    try {
      setIsSendingDocument(true);

      const signers = [];

      if (selectedRecipientRole === RecipientRolesTabGroupOptions.BUYER) {
        signers.push({
          id: localBuyer.id,
          name: `${localBuyer.name} ${localBuyer.surname}`,
          role: 'buyer',
          ...(localBuyer.phone && { phone: localBuyer.phone }),
          ...(localBuyer.email && { email: localBuyer.email }),
          sendingMethod: buyerSendingMethod,
        });
      }

      if (selectedRecipientRole === RecipientRolesTabGroupOptions.SELLER) {
        signers.push({
          id: localSeller.id,
          name: `${localSeller.name} ${localSeller.surname}`,
          role: 'seller',
          ...(localSeller.phone && { phone: localSeller.phone }),
          ...(localSeller.email && { email: localSeller.email }),
          sendingMethod: sellerSendingMethod,
        });
      }

      if (selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) {
        signers.push(
          {
            id: localBuyer.id,
            name: `${localBuyer.name} ${localBuyer.surname}`,
            role: 'buyer',
            ...(localBuyer.phone && { phone: localBuyer.phone }),
            ...(localBuyer.email && { email: localBuyer.email }),
            sendingMethod: buyerSendingMethod,
          },
          {
            id: localSeller.id,
            name: `${localSeller.name} ${localSeller.surname}`,
            role: 'seller',
            ...(localSeller.phone && { phone: localSeller.phone }),
            ...(localSeller.email && { email: localSeller.email }),
            sendingMethod: sellerSendingMethod,
          },
        );
      }

      if (templateType === 'Mandato' && !isMotoOcasion) {
        const { agent } = procedure;
        signers.push({
          id: agent.id,
          name: `${agent.name} ${agent.surname}`,
          role: 'agency',
          email: agent.email,
          sendingMethod: 'EMAIL',
        });
      }

      let entityType = '';
      let entityCode = '';

      if (isBateTramit(procedure)) {
        entityType = 'bate';
        entityCode = procedure.bate.bateCode;
      }

      if (isRegistrationTramit(procedure)) {
        entityType = 'registration';
        entityCode = procedure.registration.registrationCode;
      }

      if (isTransfer(procedure)) {
        entityType = 'transaction';
        entityCode = procedure.transaction.transactionCode;
      }

      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_SUZUKI_URL}/documents-pro`,
        {
          entityType,
          entityCode,
          pdfInBase64: documentPDF.data,
          id: uuidv4(),
          title: documentPDF.fileName,
          type: 'custom',
          templateType,
          signers,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setIsSendingDocument(false);
      hide();
      forceUpdate();
    } catch (error) {
      setIsSendingDocument(false);
    }
  };

  const handleGoBack = () => {
    if (chosenDocumentSource === DocumentSourceOptions.USE_TEMPLATE) {
      setCurrentDigitallySignStep(DigitallySignSteps.EDIT_GENERATED_DOCUMENT);
      return;
    }
    setCurrentDigitallySignStep(DigitallySignSteps.UPLOAD_DOCUMENT);
  };

  const isAbleToSendDocument = () => {
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.BUYER) {
      return hasCommunicationMethod(localBuyer);
    }
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.SELLER) {
      return hasCommunicationMethod(localSeller);
    }
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) {
      return (
        hasCommunicationMethod(localBuyer) ||
        hasCommunicationMethod(localSeller)
      );
    }
    return false;
  };

  const hasNoSendingMethodSelected = (): boolean => {
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.BUYER) {
      return buyerSendingMethod === '';
    }
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.SELLER) {
      return sellerSendingMethod === '';
    }
    if (selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) {
      return sellerSendingMethod === '' || buyerSendingMethod === '';
    }
    return false;
  };

  const canShowWarningMessageMissingInfo = (): boolean => {
    if (selectedRecipientRole !== RecipientRolesTabGroupOptions.BOTH)
      return false;

    return (
      !hasCommunicationMethod(localBuyer) ||
      !hasCommunicationMethod(localSeller)
    );
  };

  const canShowWarningMessageDuplicatedInfo = (): boolean => {
    if (selectedRecipientRole !== RecipientRolesTabGroupOptions.BOTH) {
      return false;
    }

    if (sellerSendingMethod === 'EMAIL' && buyerSendingMethod === 'EMAIL') {
      return (
        localBuyer?.email &&
        localSeller?.email &&
        localBuyer?.email === localSeller?.email
      );
    }

    if (
      sellerSendingMethod === 'WHATSAPP' &&
      buyerSendingMethod === 'WHATSAPP'
    ) {
      return (
        localBuyer?.phone &&
        !localSeller?.phone &&
        localBuyer?.phone === localSeller?.phone
      );
    }

    return false;
  };

  return (
    <section className="flex flex-col items-center gap-9 w-[700px]">
      <header className="flex flex-col gap-3 w-full">
        <h3 className="text-4xl font-bold text-zinc-700">
          Elegir destinatarios
        </h3>
      </header>
      {!isRegistrationTramit(procedure) && (
        <RecipientsTabGroup
          selectedRecipient={selectedRecipientRole}
          setSelectedRecipient={setSelectedRecipientRole}
        />
      )}
      {canShowWarningMessageMissingInfo() && (
        <article className="flex items-center gap-2 bg-yellow-50 text-yellow-800 border border-yellow-800 rounded-xl p-4 w-full">
          <MdOutlineWarningAmber className="size-5" />
          Debes rellenar la información del{' '}
          {!hasCommunicationMethod(localBuyer) ? 'comprador' : 'vendedor'}.
        </article>
      )}
      {canShowWarningMessageDuplicatedInfo() && (
        <article className="flex items-center gap-2 bg-yellow-50 text-yellow-800 border border-yellow-800 rounded-xl p-4 w-full">
          <MdOutlineWarningAmber className="size-5" />
          Los firmantes no pueden tener el mismo correo o teléfono.
        </article>
      )}
      <section className="flex flex-col gap-6 w-full">
        {(selectedRecipientRole === RecipientRolesTabGroupOptions.BUYER ||
          selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) && (
          <RecipientItem
            recipientRole={AvailableRecipientRoles.BUYER}
            recipient={localBuyer}
            updateRecipient={setLocalBuyer}
            isMotoOcasion={isMotoOcasion}
            recipientSendingMethod={buyerSendingMethod}
            setRecipientSendingMethod={setBuyerSendingMethod}
          />
        )}
        {(selectedRecipientRole === RecipientRolesTabGroupOptions.SELLER ||
          selectedRecipientRole === RecipientRolesTabGroupOptions.BOTH) && (
          <RecipientItem
            recipientRole={AvailableRecipientRoles.SELLER}
            recipient={localSeller}
            updateRecipient={setLocalSeller}
            isMotoOcasion={isMotoOcasion}
            recipientSendingMethod={sellerSendingMethod}
            setRecipientSendingMethod={setSellerSendingMethod}
          />
        )}
      </section>
      <footer className="w-full flex justify-between gap-8">
        <button
          type="button"
          className="flex items-center gap-1.5 px-3 py-2 hover:bg-zinc-100 rounded-lg active:bg-gray-200"
          onClick={handleGoBack}
        >
          <MdArrowBack className="size-5" />
          Atrás
        </button>
        <button
          type="button"
          disabled={
            !isAbleToSendDocument() ||
            isSendingDocument ||
            hasNoSendingMethodSelected() ||
            canShowWarningMessageMissingInfo() ||
            canShowWarningMessageDuplicatedInfo()
          }
          className="flex items-center gap-1.5 px-3 py-2 bg-blue-800 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-900 active:bg-blue-950"
          onClick={handleSendDocument}
        >
          Enviar a firmar
          <MdSend className="size-5" />
        </button>
      </footer>
    </section>
  );
}
