import { CheckIcon, XIcon } from '@heroicons/react/solid';

import { procedures } from '../constants';
import PaginationFooter from '../../../../components/PaginationFooter';

function PricePlansTableList({
  data,
  page,
  goNext,
  goPrev,
  setPricePlanEdited,
}) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Nombre
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Cuentas
                  </th>
                  {procedures.map((procedure) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {procedure.label}
                    </th>
                  ))}
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Editar</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.pricePlans.map((pricePlan, i) => (
                  <tr
                    key={pricePlan.id}
                    className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {pricePlan.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 text-center">
                      {pricePlan.accountCount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.transactionPrice.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.batecomBuyPrice.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.batecomSellPrice.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.enrollmentPrice.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.duplicatePrice.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.duplicateDueToModification.toLocaleString(
                        'es-ES',
                        {
                          minimumFractionDigits: 2,
                          style: 'currency',
                          currency: 'EUR',
                        },
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.domainReservationPrice.toLocaleString(
                        'es-ES',
                        {
                          minimumFractionDigits: 2,
                          style: 'currency',
                          currency: 'EUR',
                        },
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.lienRelease.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.sellNotification.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.environmentBadgePrice.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.shipmentPrice.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.trafficReportPrice.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.vehicleRegistration.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.vehicleDeregistration.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.vehicleImport.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.redPlates.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.serviceTypeChange.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.isolatedServiceTypeChange.toLocaleString(
                        'es-ES',
                        {
                          minimumFractionDigits: 2,
                          style: 'currency',
                          currency: 'EUR',
                        },
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.itpTax.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.IMTax.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {pricePlan.IVTMTax.toLocaleString('es-ES', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                    <td className="px-6 py-4 flex">
                      {pricePlan.omitInvoice ? (
                        <CheckIcon className="text-teal-500 h-6 m-auto" />
                      ) : (
                        <XIcon className="text-red-500 h-6 m-auto" />
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {pricePlan.omitServicePayment ? (
                        <CheckIcon className="text-teal-500 h-6 m-auto" />
                      ) : (
                        <XIcon className="text-red-500 h-6 m-auto" />
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer hover:text-blue-600">
                      <button
                        type="button"
                        onClick={() => {
                          setPricePlanEdited(
                            JSON.parse(JSON.stringify(pricePlan)),
                          );
                        }}
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationFooter
              data={data.pricePlans}
              page={page}
              goNext={goNext}
              goPrev={goPrev}
              total={data.count}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricePlansTableList;
