import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import React, {
  createContext,
  useEffect,
  ReactNode,
  useMemo,
  useState,
  useReducer,
} from 'react';

import { BateTramit, RelatedTransaction } from '../../types/types';

export interface BateDetailsContextType {
  bateTramit: BateTramit;
  bateLoading: boolean;
  forceUpdate: () => void;
  relatedTramit: RelatedTransaction;
}

export const bateDetailsContext = createContext<BateDetailsContextType>(
  {} as BateDetailsContextType,
);

export function BateDetailsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [bateTramit, setBateTramit] = useState<BateTramit | null>(null);
  const [bateLoading, setBateLoading] = useState<boolean>(false);
  const [relatedTramit, setRelatedTramit] = useState<RelatedTransaction>(null);

  const [updater, forceUpdateBate] = useReducer((x) => x + 1, 0);

  const { bateCode } = useParams();

  const { getAccessTokenSilently } = useAuth0();

  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  const getBateByCode = async () => {
    setBateLoading(true);
    const token = await getAccessTokenSilently();
    const { data } = await axios.get(`${BASE_URL}/v1/bate/${bateCode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setBateTramit(data);
    setBateLoading(false);
  };

  useEffect(() => {
    if (!bateTramit?.bate.id) return;
    const getTransactionByBateId = async () => {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v1/bate/${bateTramit.bate.id}/transaction`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setRelatedTramit(data);
    };

    getTransactionByBateId();
  }, [bateTramit?.bate.id]);

  useEffect(() => {
    getBateByCode();
  }, [updater, bateCode]);

  const value = useMemo(
    () => ({
      bateTramit,
      bateLoading,
      relatedTramit,
      forceUpdate: forceUpdateBate,
    }),
    [bateTramit, bateLoading, relatedTramit],
  );
  return (
    <bateDetailsContext.Provider value={value}>
      {children}
    </bateDetailsContext.Provider>
  );
}
