export const procedures = [
  {
    label: 'Transferencia',
    name: 'transactionPrice',
    type: 'number',
  },
  {
    label: 'Batecom - compra (BATE)',
    name: 'batecomBuyPrice',
    type: 'number',
  },
  {
    label: 'Batecom - venta (COM)',
    name: 'batecomSellPrice',
    type: 'number',
  },
  {
    label: 'Matriculación',
    name: 'enrollmentPrice',
    type: 'number',
  },
  {
    label: 'Duplicado',
    name: 'duplicatePrice',
    type: 'number',
  },
  {
    label: 'Duplicado por reforma',
    name: 'duplicateDueToModification',
    type: 'number',
  },
  {
    label: 'Reserva de Dominio',
    name: 'domainReservationPrice',
    type: 'number',
  },
  {
    label: 'Gestión carta de pago',
    name: 'lienRelease',
    type: 'number',
  },
  {
    label: 'Notificación de venta',
    name: 'sellNotification',
    type: 'number',
  },
  {
    label: 'Distintivo Medioambiental',
    name: 'environmentBadgePrice',
    type: 'number',
  },
  {
    label: 'Envío',
    name: 'shipmentPrice',
    type: 'number',
  },
  {
    label: 'Informe DGT',
    name: 'trafficReportPrice',
    type: 'number',
  },
  {
    label: 'Alta',
    name: 'vehicleRegistration',
    type: 'number',
  },
  {
    label: 'Baja',
    name: 'vehicleDeregistration',
    type: 'number',
  },
  {
    label: 'Importación',
    name: 'vehicleImport',
    type: 'number',
  },
  {
    label: 'Placas rojas',
    name: 'redPlates',
    type: 'number',
  },
  {
    label: 'Cambio de servicio',
    name: 'serviceTypeChange',
    type: 'number',
  },
  {
    label: 'Cambio de servicio (aislado)',
    name: 'isolatedServiceTypeChange',
    type: 'number',
  },
  {
    label: 'Liquidación ITP',
    name: 'itpTax',
    type: 'number',
  },
  {
    label: 'Liquidación IM',
    name: 'IMTax',
    type: 'number',
  },
  {
    label: 'Liquidación IVTM',
    name: 'IVTMTax',
    type: 'number',
  },
  {
    label: 'Omitir factura',
    name: 'omitInvoice',
    type: 'boolean',
  },
  {
    label: 'Omitir pago del servicio',
    name: 'omitServicePayment',
    type: 'boolean',
  },
];

export const procedureWithName = [
  {
    label: 'Nombre',
    name: 'name',
    type: 'text',
  },
  ...procedures,
];

export const initialState = {
  name: '',
  transactionPrice: 0,
  batecomBuyPrice: 0,
  batecomSellPrice: 0,
  enrollmentPrice: 0,
  duplicatePrice: 30,
  duplicateDueToModification: 40,
  domainReservationPrice: 50,
  lienRelease: 15,
  sellNotification: 30,
  environmentBadgePrice: 1.5,
  shipmentPrice: 4.5,
  trafficReportPrice: 3,
  vehicleRegistration: 30,
  vehicleDeregistration: 30,
  vehicleImport: 80,
  redPlates: 40,
  serviceTypeChange: 3,
  isolatedServiceTypeChange: 30,
  itpTax: 15,
  IMTax: 5,
  IVTMTax: 5,
  omitInvoice: false,
  omitServicePayment: false,
};
