import stringSimilarity from 'string-similarity';
import { generateShipmentItemsString } from '../../../../../../helpers/generateShipmentItemsString';
import { classNames } from '../../../../../../helpers';

export default function ShipmentBatcherTableRows({
  shipments,
  deliveryAddresses,
  selectedShimpents,
  setSelectedShipments,
}) {
  const getShipmentAddressLabel = (shipmentFullAddress) => {
    let text;

    if (!shipmentFullAddress) {
      return { color: 'bg-green-500', text: 'sin dirección' };
    }

    const deliveryAddressesFullStrings = deliveryAddresses.data
      .map((address) => address.deliveryAddress?.toLowerCase())
      .filter(Boolean);

    if (!deliveryAddressesFullStrings.length && shipmentFullAddress) {
      return { color: 'bg-red-500', text: 'a cliente final' };
    }
    if (!deliveryAddressesFullStrings.length && !shipmentFullAddress) {
      return { color: 'bg-yellow-500', text: 'empresa sin direción' };
    }

    const {
      bestMatch: { rating },
      bestMatchIndex,
    } = stringSimilarity.findBestMatch(
      shipmentFullAddress,
      deliveryAddressesFullStrings,
    );

    let color;

    if (rating <= 0.4) {
      color = 'bg-red-500';
      text = 'a cliente final';
    }
    if (rating > 0.4 && rating <= 0.6) {
      color = 'bg-yellow-500';
      text = `A oficina (${deliveryAddresses.data[bestMatchIndex]?.referenceName})`;
    }
    if (rating > 0.6) {
      color = 'bg-green-500';
      text = `A oficina (${deliveryAddresses.data[bestMatchIndex]?.referenceName})`;
    }

    return { color, text };
  };

  return shipments.data.map((shipment) => {
    const shipmentFullAddress = shipment.delivery.street.toLowerCase();

    const { color, text } = getShipmentAddressLabel(shipmentFullAddress);

    return (
      <tr
        key={shipment.id}
        className={classNames(
          'flex flex-col w-full pb-4',
          selectedShimpents.includes(shipment.id) && 'bg-gray-50',
        )}
      >
        <div className="flex">
          <td className="flex flex-col justify-center relative pt-4 pb-2 px-6 w-[10%]">
            <div
              className={classNames(
                'absolute inset-y-0 left-0 w-0.5',
                selectedShimpents.includes(shipment.id)
                  ? 'bg-indigo-600'
                  : 'bg-transparent',
              )}
            />
            <input
              type="checkbox"
              className="left-4 top-1/2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
              checked={selectedShimpents.includes(shipment.id)}
              onChange={() =>
                setSelectedShipments((prev) =>
                  selectedShimpents.includes(shipment.id)
                    ? prev.filter((s) => s !== shipment.id)
                    : [...prev, shipment.id],
                )
              }
            />
          </td>
          <td
            className={classNames(
              'flex flex-col justify-center gap-y-2 whitespace-nowrap pt-4 pb-2 pr-3 text-sm font-medium w-[30%]',
              selectedShimpents.includes(shipment.id)
                ? 'text-indigo-600'
                : 'text-gray-900',
            )}
          >
            {shipment.plate ?? 'Sin matrícula ⚠️'}
          </td>
          <td
            className={classNames(
              'flex flex-col justify-center whitespace-nowrap pt-4 pb-2 pr-3 text-sm font-light w-[50%]',
              selectedShimpents.includes(shipment.id)
                ? 'text-indigo-600'
                : 'text-gray-900',
            )}
          >
            {generateShipmentItemsString(shipment.items)}
          </td>
        </div>
        <div
          className={classNames(
            'ml-[54px] w-fit flex justify-center py-1 px-2 rounded-md text-white text-xs',
            color,
          )}
        >
          <span>{text}</span>
        </div>
      </tr>
    );
  });
}
