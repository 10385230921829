import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { queryParamsContext } from '../../context/QueryParamsContext/QueryParamsContext';
import {
  isValidTransferStatusCategory,
  TransferStatusCategories,
} from '../enums/TransferStatusCategories';
import { TransferForTable } from '../index';
import { usePage } from '../../shared/hooks/usePage';

type TransferFromQuery = {
  tramitCode: string;
  createdAt: string;
  plate: string;
  status: number;
  incidentType: string | null;
  type: string;
  intermediaryId: string;
  sellerId: string;
  buyerId: string;
};

export const LIMIT = 10;

export const useTransfers = () => {
  const [hasLoadedTransfers, setHasLoadedTransfers] = useState(false);
  const [transfers, setTransfers] = useState<TransferForTable[]>([]);
  const { getSingleParam, params } = useContext(queryParamsContext);
  const { page, setPage } = usePage();
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasFoundAnyTransfer, setHasFoundAnyTransfer] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchTransfers = async () => {
      setHasLoadedTransfers(false);
      const token = await getAccessTokenSilently();

      const {
        data: { count, transfers: receivedAllTransfers },
      } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/list-transactions-pro`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (count === 0 || receivedAllTransfers.length === 0) {
        setHasLoadedTransfers(true);
        return;
      }

      setHasFoundAnyTransfer(true);

      const calculateParams = () => {
        const statusCategory = getSingleParam('status_category');
        if (!isValidTransferStatusCategory(statusCategory)) {
          return new URLSearchParams(
            `page=${page}&${TransferStatusCategories.ONGOING}=true`,
          );
        }
        if (statusCategory === TransferStatusCategories.ALL) {
          return new URLSearchParams(`page=${page}`);
        }
        return new URLSearchParams(`page=${page}&${statusCategory}=true`);
      };

      const { data: fetchedTransfers } = await axios.get(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/list-transactions-pro?${calculateParams()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setTransfers(
        fetchedTransfers.transfers.map((t: TransferFromQuery) => ({
          plate: t.plate,
          tramitCode: t.tramitCode,
          type: t.type,
          status: t.status,
          createdAt: t.createdAt,
          incidentType: t.incidentType,
        })),
      );
      setTotalCount(fetchedTransfers.count);
      setTotalPages(Math.ceil(fetchedTransfers.count / LIMIT));
      setHasLoadedTransfers(true);
    };

    fetchTransfers();
  }, [params]);

  return {
    hasLoadedTransfers,
    transfers,
    page,
    setPage,
    totalCount,
    totalPages,
    hasFoundAnyTransfer,
  };
};
