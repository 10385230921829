import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useFeatureFlagEnabled } from 'posthog-js/react';

type BannerMessageProps = {
  message: string;
  bgColor: string;
  textColor: string;
  onClose: () => void;
};

function BannerMessage({
  message,
  bgColor,
  textColor,
  onClose,
}: BannerMessageProps) {
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8">
      <div
        className={`pointer-events-auto flex items-center justify-between gap-x-6 ${bgColor} px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5`}
      >
        <p className={`text-sm/6 ${textColor} justify-center`}>{message}</p>
        <button
          type="button"
          className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
          onClick={onClose}
        >
          <XIcon aria-hidden="true" className={`size-5 ${textColor}`} />
        </button>
      </div>
    </div>
  );
}

function Banner() {
  const [showBanner, setShowBanner] = useState(true);
  const closeBanner = () => setShowBanner(false);

  const dgtDown = useFeatureFlagEnabled('banner-dgt-down');
  const cataloniaNationalDay = useFeatureFlagEnabled(
    'banner-catalonia-national-day',
  );
  const christmasDays = useFeatureFlagEnabled('banner-christmas-days');

  const banners = [
    {
      id: 1,
      enabled: christmasDays,
      message:
        'Los días 24, 26 y 31 de diciembre DGT no permitirá a las gestorías presentar ni finalizar trámites.',
      bgColor: 'bg-yellow-100',
      textColor: 'text-yellow-700',
    },
    {
      id: 2,
      enabled: cataloniaNationalDay,
      message:
        'El día 11 de Septiembre DGT no permitirá a las gestorías presentar ni finalizar trámites.',
      bgColor: 'bg-yellow-100',
      textColor: 'text-yellow-700',
    },
    {
      id: 3,
      enabled: dgtDown,
      message:
        'En estos momentos DGT está temporalmente fuera de servicio. Esto puede ocasionar retrasos en la finalización de los trámites, la gestión será completada tan pronto como la DGT restablezca su funcionamiento.',
      bgColor: 'bg-red-100',
      textColor: 'text-red-700',
    },
  ];

  if (!showBanner) return null;

  return (
    <>
      {banners
        .filter((banner) => banner.enabled)
        .map((banner) => (
          <BannerMessage
            key={banner.id}
            message={banner.message}
            bgColor={banner.bgColor}
            textColor={banner.textColor}
            onClose={closeBanner}
          />
        ))}
    </>
  );
}

export default Banner;
