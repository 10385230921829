import { useEffect, useState } from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';

import { procedureWithName, initialState } from '../constants';
import BaseModal from '../../../../components/BaseModal';
import { useServices } from '../../../../services';
import Spinner from '../../../../components/Spinner';
import { asyncState } from '../../../../helpers/enums';

export default function PricePlanCreateModal({
  isCreateModalOpen,
  setIsCreateModalOpen,
  fetchPricePlans,
}) {
  const [formState, setFormState] = useState(initialState);
  const [asyncStatus, setAsyncStatus] = useState(asyncState.INITIAL);
  const [errorMessage, setErrorMessage] = useState('');

  const { createPricePlan } = useServices();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setAsyncStatus(asyncState.LOADING);
    setErrorMessage('');
    try {
      if (!formState.name) {
        setErrorMessage('Se debe asignar un nombre al plan de pago');
        throw new Error();
      }
      await createPricePlan({
        name: formState.name,
        transactionPrice: Number(formState.transactionPrice),
        batecomBuyPrice: Number(formState.batecomBuyPrice),
        batecomSellPrice: Number(formState.batecomSellPrice),
        duplicatePrice: Number(formState.duplicatePrice),
        duplicateDueToModification: Number(
          formState.duplicateDueToModification,
        ),
        enrollmentPrice: Number(formState.enrollmentPrice),
        domainReservationPrice: Number(formState.domainReservationPrice),
        lienRelease: Number(formState.lienRelease),
        sellNotification: Number(formState.sellNotification),
        environmentBadgePrice: Number(formState.environmentBadgePrice),
        shipmentPrice: Number(formState.shipmentPrice),
        trafficReportPrice: Number(formState.trafficReportPrice),
        vehicleRegistration: Number(formState.vehicleRegistration),
        vehicleDeregistration: Number(formState.vehicleDeregistration),
        vehicleImport: Number(formState.vehicleImport),
        redPlates: Number(formState.redPlates),
        serviceTypeChange: Number(formState.serviceTypeChange),
        isolatedServiceTypeChange: Number(formState.isolatedServiceTypeChange),
        itpTax: Number(formState.itpTax),
        IMTax: Number(formState.IMTax),
        IVTMTax: Number(formState.IVTMTax),
        omitInvoice: formState.omitInvoice,
        omitServicePayment: formState.omitServicePayment,
      });
      await fetchPricePlans();
      setAsyncStatus(asyncState.SUCCESS);
      setIsCreateModalOpen(false);
    } catch (e) {
      setAsyncStatus(asyncState.ERROR);
    }
  };

  const handleChange = (e) =>
    setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeCheckbox = (e) =>
    setFormState((prev) => ({ ...prev, [e.target.name]: e.target.checked }));

  useEffect(() => {
    if (!isCreateModalOpen) {
      setFormState(initialState);
      setAsyncStatus(asyncState.INITIAL);
      setErrorMessage('');
    }
  }, [isCreateModalOpen]);

  return (
    <BaseModal
      isOpen={isCreateModalOpen}
      onClose={() => setIsCreateModalOpen(false)}
    >
      <div className="space-y-6">
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-1 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                Crear Plan de Pago
              </h3>
              {asyncStatus === asyncState.ERROR && (
                <p className="text-red-500">
                  {errorMessage || 'Ha ocurrido un error'}
                </p>
              )}
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-y-2">
                  {procedureWithName.map((procedure) => {
                    switch (procedure.type) {
                      case 'text': {
                        return (
                          <div key={procedure.name}>
                            <label
                              htmlFor={procedure.name}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              {procedure.label}
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name={procedure.name}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                value={formState[procedure.name]}
                              />
                            </div>
                          </div>
                        );
                      }
                      case 'number': {
                        return (
                          <div key={procedure.name}>
                            <label
                              htmlFor={procedure.name}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              {procedure.label}
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm">
                                  €
                                </span>
                              </div>
                              <input
                                type="number"
                                name={procedure.name}
                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="0,00"
                                aria-describedby="price-currency"
                                step={0.01}
                                min={0}
                                max={300}
                                onChange={handleChange}
                                value={formState[procedure.name]}
                                required
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <span
                                  className="text-gray-500 sm:text-sm"
                                  id="price-currency"
                                >
                                  EUR
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      case 'boolean': {
                        return (
                          <div key={procedure.name}>
                            <label
                              htmlFor={procedure.name}
                              className="text-sm font-medium leading-6 text-gray-900"
                            >
                              {procedure.label}
                            </label>
                            <input
                              name={procedure.name}
                              type="checkbox"
                              onChange={handleChangeCheckbox}
                              value={formState[procedure.name]}
                              className="ml-4 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                            />
                          </div>
                        );
                      }
                      default:
                        return null;
                    }
                  })}
                  <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          Omitir la factura implica omitir el pago, es decir,
                          si&nbsp;
                          <span className="font-medium text-yellow-800">
                            &quot;Omitir factura&quot;
                          </span>
                          &nbsp;está activado,&nbsp;
                          <span className="font-medium text-yellow-800">
                            &quot;Omitir pago del servicio&quot;
                          </span>
                          &nbsp;no tendrá efecto. (No afecta a Informe DGT)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    disabled={asyncStatus === asyncState.LOADING}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {asyncStatus === asyncState.LOADING && <Spinner />}
                    {asyncStatus === asyncState.LOADING
                      ? 'Añadiendo...'
                      : 'Guardar cambios'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}
