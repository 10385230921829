import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const isDev = process.env.REACT_APP_ENV !== 'PROD';

export const typeNameListDict = {
  sellBuyContract: 'contrato-compraventa',
  provisional: 'provisional',
  presentationProof: 'justificante-presentacion',
  paidItpFile: 'paidItpFile',
  paidItpProof: 'paidItpProof',
  proInvoice: 'factura-profesional',
};

export const useServices = () => {
  const { getAccessTokenSilently } = useAuth0();

  const axiosClient = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return axios.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, [getAccessTokenSilently]);

  const axiosClientSaab = useCallback(
    async () =>
      axios.create({
        baseURL: process.env.REACT_APP_SAAB_URL,
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }),
    [getAccessTokenSilently],
  );

  const axiosClientToyota = useCallback(
    async () =>
      axios.create({
        baseURL: process.env.REACT_APP_TOYOTA_URL,
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }),
    [getAccessTokenSilently],
  );

  const axiosClientShipments = useCallback(
    async () =>
      axios.create({
        baseURL: process.env.REACT_APP_ZANELLA_URL,
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }),
    [getAccessTokenSilently],
  );

  return {
    getAccountById: async (accountId) => {
      const client = await axiosClient();
      return client.get(`/account/${accountId}`).then((res) => res.data);
    },

    getAllAccounts: async (page) => {
      const client = await axiosClient();
      const queryString = page !== undefined ? `?page=${page}` : '';
      return client.get(`/account${queryString}`).then((res) => res.data);
    },

    searchAccountsByName: async (name) => {
      const client = await axiosClient();
      return client
        .get(`/search-accounts/name/${name}`)
        .then((res) => res.data);
    },

    createNewAccount: async (payload) => {
      const client = await axiosClient();
      return client.post('/account', payload).then((res) => res.data);
    },

    sendProcedureToA9: async (payload) => {
      const client = await axiosClient();
      return client.post('/v1/a9/procedure', payload).then((res) => res.data);
    },

    createNewAccountMember: async (accountId, payload) => {
      const client = await axiosClient();
      return client
        .post(`/account/${accountId}/member/`, payload)
        .then((res) => res.data);
    },

    addNewAccountMember: async (accountId, memberId) => {
      const client = await axiosClient();
      return client
        .put(`/account/${accountId}/members`, { memberId })
        .then((res) => res.data);
    },

    removeAccountMember: async (accountId, memberId) => {
      const client = await axiosClient();
      return client
        .delete(`/account/${accountId}/${memberId}`)
        .then((res) => res.data);
    },

    findOrCreateCompany: async (payload) => {
      const client = await axiosClient();
      return client.post('/company/nif', payload).then((res) => res.data);
    },

    getAccountMembers: async (accountId) => {
      const client = await axiosClient();
      return client
        .get(`/account/${accountId}/members`)
        .then((res) => res.data);
    },

    getAccountTramitIdsByStatus: async (accountId, status) => {
      const client = await axiosClient();
      return client
        .get(`/account/${accountId}/tramits/${status}`)
        .then((res) => res.data);
    },

    getSwipooMembers: async () => {
      const client = await axiosClient();
      return client.get(`/swipoo/members`).then((res) => res.data);
    },

    getResetPasswordLink: async (memberId) => {
      const client = await axiosClient();
      return client
        .put(`/member/reset-password/${memberId}`)
        .then((res) => res.data);
    },

    getCompaniesRepresentedByUser: useCallback(
      async (userId) => {
        const client = await axiosClient();
        return client
          .get(`/get-companies-represented-by-user/${userId}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    searchCompanies: async ({ name }) => {
      const client = await axiosClient();
      let queryString = '';
      if (name) queryString += `name=${name}`;
      return client
        .get(`/search-companies?${queryString}`)
        .then((res) => res.data);
    },

    getUserById: useCallback(
      async (id) => {
        const client = await axiosClient();
        return client.get(`/get-user-info/${id}`).then((res) => res.data);
      },
      [axiosClient],
    ),

    getCarById: async (id) => {
      const client = await axiosClient();
      return client.get(`/get-car-info/${id}`).then((res) => res.data);
    },

    getAllAgents: useCallback(
      async ({ page, signal }) => {
        const queryString = `page=${page}`;
        const client = await axiosClient();
        return client
          .get(`/list-agents?${queryString}`, {
            signal,
          })
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    searchAgentsByName: useCallback(
      async (name) => {
        const queryString = `name=${name}`;
        const client = await axiosClient();
        return client
          .get(`/agents/search?${queryString}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getAllTransfers: useCallback(
      async ({
        page,
        isB2B,
        hasIncident,
        status,
        isNotPaid,
        minDate,
        maxDate,
        minItp,
        maxItp,
        signal,
        dateSort,
        incidentType,
        ccaa,
        carOlderThan10Years,
        assignedEmail,
        companyId,
        accountId,
        missingTemporaryCirculationDoc,
        dniAboutToExpire,
        hasPhysicalContract,
        labels,
        agentId,
        initialStateParam,
        finalStateParam,
        minDateStatusChange,
        maxDateStatusChange,
        host,
      }) => {
        let queryString = `page=${page}`;
        queryString += `&dateSort=${dateSort}`;
        if (hasIncident) queryString += '&hasIncident=true';
        if (isB2B === true) {
          queryString += '&isB2B=true';
        } else if (isB2B === false) {
          queryString += '&isB2B=false';
        }
        if (isNotPaid) queryString += '&isNotPaid=true';
        if (typeof status === 'number') queryString += `&status=${status}`;
        if (minDate && maxDate) {
          queryString += `&minDate=${minDate}&maxDate=${maxDate}`;
        }
        if (minItp && maxItp) {
          queryString += `&minItp=${minItp}&maxItp=${maxItp}`;
        }
        if (incidentType) queryString += `&incidentType=${incidentType}`;
        if (ccaa) queryString += `&ccaa=${ccaa}`;
        if (carOlderThan10Years) queryString += '&carOlderThan10Years=true';
        if (assignedEmail) queryString += `&assignedEmail=${assignedEmail}`;
        if (companyId) queryString += `&companyId=${companyId}`;
        if (accountId) queryString += `&accountId=${accountId}`;
        if (missingTemporaryCirculationDoc)
          queryString += '&missingTemporaryCirculationDoc=true';
        if (dniAboutToExpire) queryString += '&dniAboutToExpire=true';
        if (hasPhysicalContract) queryString += '&hasPhysicalContract=true';
        if (labels) {
          labels.forEach((label) => {
            queryString += `&labels=${label}`;
          });
        }
        if (agentId) queryString += `&agentId=${agentId}`;
        if (finalStateParam && minDateStatusChange && maxDateStatusChange) {
          queryString += `&finalStateParam=${finalStateParam}&minDateStatusChange=${minDateStatusChange}&maxDateStatusChange=${maxDateStatusChange}`;
          if (initialStateParam) {
            queryString += `&initialStateParam=${initialStateParam}`;
          }
        }
        if (host) queryString += `&host=${host}`;

        const client = await axiosClient();
        return client
          .get(`/list-transactions?${queryString}`, {
            signal,
          })
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getAllBatecoms: useCallback(
      async ({
        page,
        signal,
        dateSort,
        incidentType,
        minDate,
        maxDate,
        companyId,
        accountId,
        hasIncident,
        labels,
        status,
        tramitType,
      }) => {
        let queryString = `page=${page}&dateSort=${dateSort}`;

        queryString += `&tramitType=${
          tramitType || 'batecom_purchase&tramitType=batecom_sale'
        }`;

        if (minDate && maxDate) {
          queryString += `&minDate=${minDate}&maxDate=${maxDate}`;
        }
        if (companyId) queryString += `&companyId=${companyId}`;
        if (accountId) queryString += `&accountId=${accountId}`;
        if (hasIncident) queryString += '&hasIncident=true';
        if (incidentType) queryString += `&incidentType=${incidentType}`;
        if (labels) {
          labels.forEach((label) => {
            queryString += `&labels=${label}`;
          });
        }
        if (typeof status === 'number') queryString += `&status=${status}`;
        const client = await axiosClient();
        return client
          .get(`/list-batecoms?${queryString}`, {
            signal,
          })
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getUserTransactions: useCallback(
      async (userId, page = 0) => {
        const queryString = `page=${page}`;
        const client = await axiosClient();
        return client
          .get(`/list-transactions/${userId}?${queryString}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getEntityTramitsUser: useCallback(
      async (id, dni, page = 0) => {
        const queryString = `id=${id}&dni=${dni}&page=${page}`;
        const client = await axiosClient();
        return client
          .get(`/related-tramits-user?${queryString}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getEntityTramitsCompany: useCallback(
      async (id, nif, page = 0) => {
        const queryString = `id=${id}&nif=${nif}&page=${page}`;
        const client = await axiosClient();
        return client
          .get(`/related-tramits-company?${queryString}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getTransactionByCode: async (code) => {
      const client = await axiosClient();
      return client
        .get(`/get-aggregated-transactions/${code}`)
        .then((res) => res.data);
    },

    getTransactionById: async (id) => {
      const client = await axiosClient();
      return client.get(`/get-transaction/id/${id}`).then((res) => res.data);
    },

    getAllHosts: async () => {
      const client = await axiosClient();
      return client.get('/list-hosts/').then((res) => res.data);
    },

    getCarRelatedTramits: useCallback(
      async (id, plate, page) => {
        const client = await axiosClient();
        const queryString = `?id=${id}&plate=${plate}&page=${page}`;
        return client
          .get(`/related-tramits-car/${queryString}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    editTransaction: async (code, payload) => {
      const client = await axiosClient();
      return client
        .put(`/edit-transaction/${code}`, payload)
        .then((res) => res.data);
    },

    getComments: async (entityId) => {
      const client = await axiosClient();
      return client
        .get(`/comment/list/${entityId}?commentType=all`)
        .then((res) => res.data);
    },

    createComment: async (payload) => {
      const client = await axiosClient();
      return client.post(`/comment`, payload).then((res) => res.data);
    },

    toggleCommentVisibility: async (commentId) => {
      const client = await axiosClient();
      return client
        .put(`/comment/toggle-visibility/${commentId}`)
        .then((res) => res.data);
    },

    deleteCommentById: async (commentId, userEmail) => {
      const client = await axiosClient();
      return client
        .delete(`/comment/delete/${commentId}`, {
          headers: {
            'x-user-email': userEmail,
          },
        })
        .then((res) => res.data);
    },

    editUser: async (userId, payload) => {
      const client = await axiosClient();
      return client
        .put(`/edit-user/${userId}`, payload)
        .then((res) => res.data);
    },

    editCar: async (carId, payload) => {
      const client = await axiosClient();
      return client.put(`/edit-car/${carId}`, payload).then((res) => res.data);
    },

    editAccount: async (accountId, payload) => {
      const client = await axiosClient();
      return client
        .put(`/account/${accountId}`, payload)
        .then((res) => res.data);
    },

    updateCarModel: async (carId, payload) => {
      const client = await axiosClient();
      return client
        .put(`/update-car-model/${carId}`, payload)
        .then((res) => res.data);
    },

    uploadFileToS3: async (file, bucketName) => {
      const formData = new FormData();
      formData.append('file', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const client = await axiosClient();
      return client
        .post(`/s3-upload-file/${bucketName}`, formData, config)
        .then((res) => res.data);
    },

    fetchAllLabels: async () => {
      const client = await axiosClient();
      return client.get('/labels').then((res) => res.data);
    },

    fetchAllLabelsByEntityType: async (entityType) => {
      const client = await axiosClient();
      return client.get(`/labels/entity/${entityType}`).then((res) => res.data);
    },

    createLabel: async (label) => {
      const client = await axiosClient();
      return client.post('/labels', label).then((res) => res.data);
    },

    editLabel: async (labelId, label) => {
      const client = await axiosClient();
      return client.put(`/labels/${labelId}`, label).then((res) => res.data);
    },

    deleteLabel: async (labelId) => {
      const client = await axiosClient();
      return client.delete(`/labels/${labelId}`).then((res) => res.data);
    },

    assignLabelToEntity: async (entityId, labelId) => {
      const client = await axiosClient();
      return client
        .post(`/entity-labels/${entityId}/${labelId}`)
        .then((res) => res.data);
    },

    removeLabelFromEntity: async (entityId, labelId) => {
      const client = await axiosClient();
      return client
        .delete(`/entity-labels/${entityId}/${labelId}`)
        .then((res) => res.data);
    },

    fetchAllIncidents: async () => {
      const client = await axiosClient();
      return client.get('/incidents').then((res) => res.data);
    },

    fetchAllIncidentsByEntityType: async (entityType) => {
      const client = await axiosClient();
      return client
        .get(`/incidents/entity/type/${entityType}`)
        .then((res) => res.data);
    },

    fetchAllIncidentsByEntityId: async (entityId, open) => {
      const client = await axiosClient();
      const queryString = open ? '?open=true' : '';
      return client
        .get(`/incidents/entity/id/${entityId}${queryString}`)
        .then((res) => res.data);
    },

    createIncident: async (incident) => {
      const client = await axiosClient();
      return client.post('/incidents', incident).then((res) => res.data);
    },

    editIncident: async (incidentId, incident) => {
      const client = await axiosClient();
      return client
        .put(`/incidents/${incidentId}`, incident)
        .then((res) => res.data);
    },

    deleteIncident: async (incidentId) => {
      const client = await axiosClient();
      return client.delete(`/incidents/${incidentId}`).then((res) => res.data);
    },

    openIncidentOnEntity: async ({
      incidentId,
      entityId,
      comment,
      createdBy,
    }) => {
      const client = await axiosClient();
      return client
        .post(`/incident/open/${incidentId}/${entityId}`, {
          comment,
          createdBy,
        })
        .then((res) => res.data);
    },

    closeIncidentOnEntity: async (incidentId, entityId) => {
      const client = await axiosClient();
      return client
        .put(`/incident/close/${incidentId}/${entityId}`)
        .then((res) => res.data);
    },

    getAllRegistrations: async (page) => {
      const client = await axiosClient();
      return client
        .get(`/v1/registrations/?page=${page}`)
        .then((res) => res.data);
    },

    getRegistrationByCode: async (code) => {
      const client = await axiosClient();
      return client.get(`/v1/registration/${code}`).then((res) => res.data);
    },

    getRegistrationById: async (id) => {
      const client = await axiosClient();
      return client.get(`/v1/registration/id/${id}`).then((res) => res.data);
    },

    editRegistration: async (code, payload) => {
      const client = await axiosClient();
      return client
        .put(`/v1/registration/${code}`, payload)
        .then((res) => res.data);
    },

    getS3SignedUrl: async (bucketName, objectKey) => {
      const client = await axiosClient();
      const { data } = await client.get(
        `/s3-get-file-signed-url/${bucketName}/${encodeURIComponent(
          objectKey,
        )}`,
      );
      return data;
    },

    createUserDni: async (payload) => {
      const client = await axiosClient();
      return client
        .post(`/create-user/internal/dni`, payload)
        .then((res) => res.data);
    },

    createUser: async (payload) => {
      const client = await axiosClient();
      return client.post(`/create-user/`, payload).then((res) => res.data);
    },

    createCar: async (payload) => {
      const client = await axiosClient();
      return client.post(`/create-car/`, payload).then((res) => res.data);
    },

    getModelsByBrand: async (brand) => {
      const client = await axiosClient();
      return client.get(`/models-by-brand/${brand}`).then((res) => res.data);
    },

    getCarBrands: async () => {
      const client = await axiosClient();
      return client.get('/brands/').then((res) => res.data);
    },

    createTransaction: async (payload) => {
      const client = await axiosClient();
      return client
        .post(`/create-transaction/`, payload)
        .then((res) => res.data);
    },

    createProTransaction: async (payload) => {
      const client = await axiosClient();
      return client
        .post(`/create-pro-transaction/`, payload)
        .then((res) => res.data);
    },

    getAllBySearchQuery: useCallback(
      async (query, page = 0) => {
        const client = await axiosClient();
        return client
          .get(`/search/?query=${encodeURIComponent(query)}&page=${page}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getOrgInfoByOrgId: async (orgId) => {
      try {
        const client = await axiosClient();
        return client
          .get(`/get-company-info/orgId/${orgId}`)
          .then((res) => res.data);
      } catch (e) {
        return null;
      }
    },

    getAccountTramitStats: async (accountId) => {
      try {
        const client = await axiosClient();
        return client
          .get(`/account-tramit-stats/${accountId}`)
          .then((res) => res.data);
      } catch (e) {
        return null;
      }
    },

    replaceCompanyRepresentative: useCallback(
      async (companyId, dni) => {
        const client = await axiosClient();
        return client
          .put(`/replace-company-representative/${companyId}`, {
            dni,
          })
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getCompanyInfoById: useCallback(
      async (companyId) => {
        const client = await axiosClient();
        return client
          .get(`/get-company-info/id/${companyId}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    editCompany: useCallback(
      async (companyId, payload) => {
        const client = await axiosClient();
        return client
          .put(`/edit-company/id/${companyId}`, payload)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getAllCompanies: useCallback(
      async (page) => {
        const client = await axiosClient();
        return client
          .get(`/list-companies${page !== undefined ? `?page=${page}` : ''}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getDigitalSignatureByTransactionCode: async (transactionCode) => {
      const token = await getAccessTokenSilently();
      const result = await axios.get(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/transaction/${transactionCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (result.data.length === 0) {
        return null;
      }

      return result.data[0];
    },

    notifySigners: async (docId) => {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/notify/${docId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },

    createDigitalSignature: async (payload, type, skipAgentSignature) => {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/${type}Contract?skipAgentSignature=${skipAgentSignature}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },

    deleteDigitalContract: async (docId, reason) => {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${process.env.REACT_APP_SUZUKI_URL}/documents/cancel/${docId}`,
        { reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },

    getAllBadges: useCallback(
      async (page) => {
        const client = await axiosClient();
        return client
          .get(`/get-all-environmental-badges?page=${page}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    createEnvironmentalBadge: async (payload) => {
      const client = await axiosClient();
      return client
        .post(`/create-environmental-badge/`, payload)
        .then((res) => res.data);
    },

    getBadgeByTransactionId: useCallback(
      async (transactionId) => {
        const client = await axiosClient();
        return client
          .get(`/get-environmental-badge/${transactionId}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getBadgeById: useCallback(
      async (id) => {
        const client = await axiosClient();
        return client
          .get(`/get-environmental-badge/id/${id}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    updateBadgeById: useCallback(
      async (id, payload) => {
        const client = await axiosClient();
        return client
          .put(`/edit-environmental-badge/${id}`, payload)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    createSetupIntent: useCallback(async (orgId) => {
      const client = await axiosClient();
      return client
        .post(`/v1/create-setup-intent/`, { orgId })
        .then((res) => res.data);
    }),

    generateManualPayment: useCallback(
      async (accountId, amount, description, paymentMethodId) => {
        const client = await axiosClient();
        return client
          .post(`/generate-manual-payment/${accountId}`, {
            amount,
            description,
            paymentMethodId,
          })
          .then((res) => res.data);
      },
    ),
    getPaymentInfo: useCallback(async (orgId, type) => {
      const client = await axiosClient();
      return client
        .get(`/v1/get-payment-info/${orgId}${type ? `?type=${type}` : ''}`)
        .then((res) => res.data);
    }),
    createHoldedInvoice: useCallback(
      async ({ transactionCode }) => {
        const client = await axiosClient();
        return client
          .post(`/create-holded-invoice/${transactionCode}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),
    getHoldedInvoice: useCallback(
      async (invoiceId) => {
        const client = await axiosClient();
        return client
          .get(`/holded-invoice-details/${invoiceId}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),
    checkStripeRefund: useCallback(
      async (transactionCode) => {
        const client = await axiosClient();
        return client
          .get(`/refund-available/${transactionCode}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),
    generateHoldedRefund: useCallback(
      async (transactionCode, items) => {
        const client = await axiosClient();
        return client
          .post(`/refund-transaction/${transactionCode}`, { items })
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getAllPricePlans: useCallback(
      async (page) => {
        const client = await axiosClient();
        return client.get(`/price-plans?page=${page}`).then((res) => res.data);
      },
      [axiosClient],
    ),
    createPricePlan: useCallback(
      async (payload) => {
        const client = await axiosClient();
        return client.post(`/price-plan`, payload).then((res) => res.data);
      },
      [axiosClient],
    ),
    updatePricePlan: useCallback(
      async (id, payload) => {
        const client = await axiosClient();
        return client.put(`/price-plan/${id}`, payload).then((res) => res.data);
      },
      [axiosClient],
    ),
    getPricePlanById: useCallback(
      async (id) => {
        const client = await axiosClient();
        return client.get(`/price-plan/${id}`).then((res) => res.data);
      },
      [axiosClient],
    ),
    searchPricePlansByName: useCallback(
      async (name) => {
        const client = await axiosClient();
        return client
          .get(`/search-price-plans?name=${name}`)
          .then((res) => res.data);
      },
      [axiosClient],
    ),

    getBateByCode: useCallback(
      async (code) => {
        const client = await axiosClient();
        return client.get(`/v1/bate/${code}`).then((res) => res.data);
      },
      [axiosClient],
    ),
    getBateById: useCallback(
      async (id) => {
        const client = await axiosClient();
        return client.get(`/v1/bate/id/${id}`).then((res) => res.data);
      },
      [axiosClient],
    ),
    editBate: useCallback(
      async (code, payload) => {
        const client = await axiosClient();
        return client.put(`/v1/bate/${code}`, payload).then((res) => res.data);
      },
      [axiosClient],
    ),

    getShipmentsByCompanyId: useCallback(
      async (companyId) => {
        const client = await axiosClientShipments();
        return client
          .get(`/shipments/company/${companyId}`)
          .then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    getShipmentsByAccountId: useCallback(
      async (accountId) => {
        const client = await axiosClientShipments();
        return client
          .get(`/shipments/account/${accountId}`)
          .then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    getSentShipmentsByAccountId: useCallback(
      async (accountId, page) => {
        const client = await axiosClientShipments();
        return client
          .get(`/shipments/account/sent/${accountId}?page=${page}`)
          .then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    createShipment: useCallback(
      async (payload) => {
        const client = await axiosClientShipments();
        return client.post(`/shipments`, payload).then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    getShipmentByEntityId: useCallback(
      async (entityId) => {
        const client = await axiosClientShipments();
        return client
          .get(`/shipments/entity/${entityId}`)
          .then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    generateBatchShipment: useCallback(
      async ({ shipmentIds, accountId, deliveryAddress }) => {
        const client = await axiosClientShipments();
        return client
          .post(`/shipments/batch`, {
            ids: shipmentIds,
            accountId,
            deliveryAddress,
          })
          .then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    getAllShipmentsByTransactionId: useCallback(
      async (transactionId, environmentalBadgeId) => {
        const client = await axiosClientShipments();
        const queryString = environmentalBadgeId
          ? `?environmentalBadgeId=${environmentalBadgeId}`
          : '';
        return client
          .get(`/shipments/all/transaction/${transactionId}${queryString}`)
          .then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    getAllShipmentsByEntityIdId: useCallback(
      async (entityId) => {
        const client = await axiosClientShipments();
        return client
          .get(`/shipments/all/entity/${entityId}`)
          .then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    updateShipment: useCallback(
      async (shipmentId, payload) => {
        const client = await axiosClientShipments();
        return client
          .put(`/shipments/${shipmentId}`, payload)
          .then((res) => res.data);
      },
      [axiosClientShipments],
    ),

    generateSingleShipment: useCallback(
      async ({ entityId, shipmentId }) => {
        const client = await axiosClientShipments();
        return client
          .post('/shipments/ready', { entityId, shipmentId })
          .then((res) => res.status);
      },
      [axiosClientShipments],
    ),

    getItemByEntityId: useCallback(
      async (entityId) => {
        const client = await axiosClientShipments();
        return client.get(`/items/entity/${entityId}`).then((res) => res);
      },
      [axiosClientShipments],
    ),

    createItem: useCallback(
      async (item) => {
        const client = await axiosClientShipments();
        return client.post(`/items`, item).then((res) => res);
      },
      [axiosClientShipments],
    ),

    createDeliveryAddress: async (payload) => {
      const client = await axiosClient();
      return client.post(`/delivery-address`, payload).then((res) => res.data);
    },

    getAllDeliveryAddress: async (accountId) => {
      const client = await axiosClient();
      return client
        .get(`/delivery-address/${accountId}`)
        .then((res) => res.data);
    },

    editDeliveryAddressById: async (id, payload) => {
      const client = await axiosClient();
      return client
        .put(`/edit-delivery-address/${id}`, payload)
        .then((res) => res.data);
    },

    copyEntityData: async (
      dataRecieverEntityId,
      dataProviderEntityId,
      entityType,
    ) => {
      const client = await axiosClient();
      return client
        .post('/copy-entity-data', {
          dataRecieverEntityId,
          dataProviderEntityId,
          entityType,
        })
        .then((res) => res.data);
    },

    searchEntities: async (query, entityType) => {
      const client = await axiosClient();
      return client
        .get(`/search-entities/${entityType}?query=${query}`)
        .then((res) => res.data);
    },

    switchEntityType: async ({
      sourceEntityId,
      sourceEntityType,
      tramitCode,
      tramitType,
      role,
    }) => {
      const client = await axiosClient();
      return client
        .post('/switch-entity-type', {
          sourceEntityId,
          sourceEntityType,
          tramitCode,
          tramitType,
          role,
        })
        .then((res) => res.data);
    },

    getAllDgtFees: async (page, type) => {
      const client = await axiosClientToyota();
      const queryString = `page=${page}&type=${encodeURIComponent(type)}`;
      return client.get(`/dgt-fee?${queryString}`).then((res) => res.data);
    },

    getAllAvailableDgtFeesByFeeType: async (type) => {
      const client = await axiosClientToyota();
      return client.get(`/dgt-fee/available/${type}`).then((res) => res.data);
    },

    getDgtFeeSummary: async (feeType) => {
      const client = await axiosClientToyota();
      return client
        .get(`/dgt-fee/summary/${encodeURIComponent(feeType)}`)
        .then((res) => res.data);
    },

    getDgtFeeTypes: async () => {
      const client = await axiosClientToyota();
      return client.get('/dgt-fee-type').then((res) => res.data);
    },

    markFeeAsInDispute: async (feeId) => {
      const client = await axiosClientToyota();
      return client
        .put(`/dgt-fee/mark-fee-as-in-dispute/${feeId}`)
        .then((res) => res.data);
    },
    markFeeAsReadyToUse: async (feeId) => {
      const client = await axiosClientToyota();
      return client
        .put(`/dgt-fee/mark-fee-as-ready-to-use/${feeId}`)
        .then((res) => res.data);
    },
    markFeeAsInQuarantine: async (feeId) => {
      const client = await axiosClientToyota();
      return client
        .put(`/dgt-fee/mark-fee-as-in-quarantine/${feeId}`)
        .then((res) => res.data);
    },

    batchCreateDgtFees: async (file) => {
      const client = await axiosClientToyota();
      const formData = new FormData();
      formData.append('file', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      return client
        .post('/dgt-fee/batch', formData, config)
        .then((res) => res.data);
    },

    getTrafficReportById: async (id) => {
      const client = await axiosClientSaab();
      return client.get(`/traffic-report/${id}`).then((res) => res.data);
    },

    getAllReports: async (page, isPro) => {
      const client = await axiosClientSaab();
      const queryString = `page=${page}&pro=${Boolean(isPro)}`;
      return client
        .get(`/traffic-report?${queryString}`)
        .then((res) => res.data);
    },

    getTrafficReport: async (plate, frameNumber) => {
      const client = await axiosClientSaab();
      return client
        .post('/traffic-report', {
          plate,
          frameNumber,
        })
        .then((res) => res.data);
    },

    uploadTrafficReport: async (
      identifier,
      identifierType,
      dgtFeeId,
      file,
      accountId,
    ) => {
      const formData = new FormData();
      formData.append('identifier', identifier);
      formData.append('identifierType', identifierType);
      formData.append('dgtFeeId', dgtFeeId);
      formData.append('file', file);

      if (accountId) formData.append('accountId', accountId);

      const client = await axiosClientSaab();
      return client
        .post('/traffic-report/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    },

    requestTrafficReportFromTransaction: async (transactionCode) => {
      const client = await axiosClient();
      return client
        .post(`/request-traffic-report/transaction/${transactionCode}`)
        .then((res) => res.data);
    },

    uploadFile: async (id, file, type, destinatary) => {
      let param = `${id}/?type=${typeNameListDict[type]}`;
      if (destinatary) param += `&destinatary=${destinatary}`;

      const formData = new FormData();
      formData.append('file', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const client = await axiosClient();
      return client
        .post(`/file-upload/${param}`, formData, config)
        .then((res) => res.data);
    },

    getMechanicalWarranty: async (carId) => {
      const client = await axiosClient();
      return client
        .get(`/car/${carId}/mechanical-warranty`)
        .then((res) => res.data);
    },

    getFeatureFlags: async () => {
      const client = await axiosClient();
      return client.get(`/feature-flag`).then((res) => res.data);
    },

    updateFeatureFlag: async (flagId, payload) => {
      const client = await axiosClient();
      return client
        .put(`/feature-flag/${flagId}`, payload)
        .then((res) => res.data);
    },

    getAllTramitabilityChecks: async (entityId, entityType) => {
      const client = await axiosClient();
      return client
        .get(`/v1/a9/tramitabilityCheck/${entityType}/${entityId}`)
        .then((res) => res.data);
    },

    generateTramitabiltyCheck: async (entityId, entityType, payload) => {
      const client = await axiosClient();
      return client
        .post(`/v1/a9/tramitabilityCheck/${entityType}/${entityId}`, payload)
        .then((res) => res.data);
    },

    getBatecomRelationByTransactionId: async (transactionId) => {
      const client = await axiosClient();
      return client
        .get(`/v1/transaction/${transactionId}/bate`)
        .then((res) => res.data);
    },

    skodaOcr: async (fileType, uri) =>
      axios
        .post(
          `https://skoda.swipoo.com/ocr/${fileType}`,
          {
            uri,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_SKODA_TOKEN}`,
            },
          },
        )
        .then((res) => res.data),
  };
};
