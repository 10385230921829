import React, { useEffect, useReducer, useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';
import {
  CheckIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';

import { useServices } from '../services';
import Spinner from './Spinner';
import classNames from '../helpers/classNames';
import { parseNotificationDate } from '../helpers';

const initState = [
  {
    id: '',
    icon: InformationCircleIcon,
    iconBackground: 'bg-gray-400',
    createdAt: '',
    status: '',
    description: '',
  },
];

export default function TramitabilityCheck({
  entityId,
  entityType,
  plate,
  sellerDoi,
  buyerDoi,
  isBatecom,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tramitabilityCheckList, setTramitabilityCheckList] =
    useState(initState);
  const [isTramitabilityCheckExpanded, setTramitabilityCheckExpanded] =
    useState(false);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);

  const {
    getAllTramitabilityChecks,
    generateTramitabiltyCheck,
    getBatecomRelationByTransactionId,
  } = useServices();

  const handleTramitabilityCheck = async () => {
    try {
      setErrorMessage('');
      setIsButtonLoading(true);
      let payload = {};
      if (isBatecom) {
        const batecom = await getBatecomRelationByTransactionId(entityId);
        payload = {
          plate,
          sellerDoi: batecom.seller?.dni || batecom.seller?.nif,
          buyerDoi,
          isBatecom,
          bridgeNif: sellerDoi,
        };
      } else {
        payload = {
          plate,
          sellerDoi,
          buyerDoi,
        };
      }
      await generateTramitabiltyCheck(entityId, entityType, payload);
      forceUpdate();
    } catch (error) {
      if (error.response?.status === 403) {
        setErrorMessage(
          'La consulta de tramitabilidad esta fuera del horario permitido (DGT)',
        );
        return;
      }
      setErrorMessage(
        'Ha ocurrido un error al generar la consulta de tramitabilidad',
      );
    } finally {
      setIsButtonLoading(false);
    }
  };

  const computeStatus = (status) => {
    if (status === 'TRAMITABLE') {
      return {
        status: 'TRAMITABLE',
        color: 'text-green-600',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
      };
    }
    const isNotAvailable = status.length === 0;
    return {
      status: isNotAvailable ? 'NO DISPONIBLE' : status,
      color: isNotAvailable ? 'text-gray-400' : 'text-red-600',
      icon: isNotAvailable ? InformationCircleIcon : ExclamationCircleIcon,
      iconBackground: isNotAvailable ? 'bg-gray-400' : 'bg-red-500',
    };
  };

  const fetchTramitabilityChecks = async (id, type) => {
    try {
      setIsLoading(true);
      const response = await getAllTramitabilityChecks(id, type);
      const formattedList = response.map((check) => ({
        id: check.id,
        icon: computeStatus(check.status).icon,
        iconBackground: computeStatus(check.status).iconBackground,
        createdAt: check.createdAt,
        status: check.status,
        description: check.description,
      }));
      setTramitabilityCheckList(formattedList);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!entityId || !entityType) return;
    fetchTramitabilityChecks(entityId, entityType);
  }, [entityId, entityType, updater]);

  const [lastCheck] = tramitabilityCheckList;

  if (isLoading) {
    return (
      <div className="px-4 py-5 sm:px-6 border-t border-gray-200">
        <div className="flex justify-center w-full">
          <Spinner color="text-blue-700" size={5} />
        </div>
      </div>
    );
  }

  const isButtonDisabled = isButtonLoading || !plate || !sellerDoi || !buyerDoi;

  return (
    <div className="px-4 py-5 sm:px-6 border-t border-gray-200">
      <div className="flex justify-between w-full">
        <h3 className="text-md font-medium leading-6 mb-1">
          Consulta de tramitabilidad:{' '}
          {!isTramitabilityCheckExpanded && (
            <span
              className={classNames(
                'ml-2',
                computeStatus(lastCheck.status).color,
              )}
            >
              {computeStatus(lastCheck.status).status}
            </span>
          )}
        </h3>
        {isTramitabilityCheckExpanded ? (
          <button
            type="button"
            onClick={() => setTramitabilityCheckExpanded(false)}
          >
            <ChevronUpIcon
              className="h-5 w-5 text-gray-400 cursor-pointer"
              aria-hidden="true"
            />
          </button>
        ) : (
          <button
            type="button"
            onClick={() => setTramitabilityCheckExpanded(true)}
          >
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400 cursor-pointer"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
      {!isTramitabilityCheckExpanded && lastCheck.description && (
        <p className="text-red-600 font-small">{lastCheck.description}</p>
      )}
      {isTramitabilityCheckExpanded && (
        <div className="mt-4">
          <ul>
            {tramitabilityCheckList.map((check, eventIdx) => (
              <li key={check.id}>
                <div className="relative pb-8">
                  {eventIdx !== tramitabilityCheckList.length - 1 ? (
                    <span
                      aria-hidden="true"
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          check.iconBackground,
                          'flex size-8 items-center justify-center rounded-full ring-8 ring-white',
                        )}
                      >
                        <check.icon
                          aria-hidden="true"
                          className="size-5 text-white"
                        />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-black-500">
                          <span
                            className={classNames(
                              computeStatus(check.status).color,
                              'font-medium mr-2',
                            )}
                          >
                            {computeStatus(check.status).status}
                          </span>{' '}
                          {check.description}
                        </p>
                      </div>
                      {check.createdAt && (
                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                          <time dateTime={check.createdAt}>
                            {parseNotificationDate(check.createdAt)}
                          </time>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex flex-none items-center gap-x-4">
            <button
              type="button"
              className={`text-white bg-[#CD8383] rounded-md px-4 py-1 text-lg flex items-center gap-2 disabled:opacity-50 ${
                isButtonDisabled
                  ? 'bg-gray-600 hover:bg-gray-500'
                  : 'bg-[#C67070] hover:bg-[#C67070]'
              }`}
              onClick={handleTramitabilityCheck}
              disabled={isButtonDisabled}
            >
              Regenerar consulta A9
            </button>
            {errorMessage && (
              <p className="text-red-600 text-sm">{errorMessage}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
