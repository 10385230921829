import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react';

import { DeliveryInfo, ReceiverInfo } from '../../types/types';
import { ccaaDictionary } from '../../../../../../enums/ccaaDictionary';

type ShipmentField = {
  value: string;
  label: string;
  shipmentKey: string;
};

type DeliveryInfoTemplateProps = {
  objectKey: string;
  data: ShipmentField;
  handleFormEdit: (
    ev: SyntheticEvent,
    setter: Dispatch<SetStateAction<DeliveryInfo | ReceiverInfo>>,
  ) => void;
  setState: Dispatch<SetStateAction<DeliveryInfo | ReceiverInfo>>;
};

export default function DeliveryInfoTemplate({
  objectKey,
  data,
  handleFormEdit,
  setState,
}: DeliveryInfoTemplateProps) {
  return (
    <div
      key={objectKey}
      className="flex items-center justify-between mb-2 text-sm font-medium text-gray-700 ml-4 h-6"
    >
      <span>{data?.label}:</span>{' '}
      <div className="flex items-center">
        {objectKey !== 'deliveryState' && (
          <input
            className="border border-gray-300 rounded-md pl-2 p-1 w-48"
            type={objectKey === 'receiverEmail' ? 'email' : 'text'}
            name={objectKey}
            value={data?.value}
            required
            onChange={(e) => handleFormEdit(e, setState)}
          />
        )}
        {objectKey === 'deliveryState' && (
          <select
            name={objectKey}
            value={data?.value}
            onChange={(e) => handleFormEdit(e, setState)}
            className="rounded-md border-gray-300 text-sm h-9 w-48"
          >
            <option value="" disabled>
              Seleccione una CCAA
            </option>
            {Object.keys(ccaaDictionary)
              .sort((a, b) => a.localeCompare(b))
              .map((state) => (
                <option key={state} value={state}>
                  {ccaaDictionary[state as keyof typeof ccaaDictionary]}
                </option>
              ))}
          </select>
        )}
      </div>
    </div>
  );
}
