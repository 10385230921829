import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Pagination from '../components/Pagination';
import { REGISTRATION_STATUS } from '../shared/registration-status';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import NewRegistrationModal from '../modals/NewRegistrationModal/NewRegistrationModal';
import RegistrationsTable, {
  GenericTableRow,
} from './components/RegistrationsTable/RegistrationsTable';
import RegistrationsTableSkeletonLoader from './components/RegistrationsTableSkeletonLoader/RegistrationsTableSkeletonLoader';
import { usePage } from '../shared/hooks/usePage';
import { RegistrationsEmptyState } from './components/EmptyState/RegistrationsEmptyState';
import Button from '../components/Button';

const LIMIT = 10;

type FullRegistrationResponse = {
  registrations: RegistrationRowResponse[];
  count: number;
};

type RegistrationRowResponse = {
  registrationCode: string;
  status: REGISTRATION_STATUS;
  createdAt: string;
  incidentType: string | null;
};

export default function RegistrationsList() {
  const [hasLoadedRegistrations, setHasLoadedRegistrations] = useState(false);
  const [registrations, setRegistrations] = useState<GenericTableRow[]>([]);
  const { params } = useContext(queryParamsContext);
  const { page, setPage } = usePage();
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasFoundAnyRegistration, setHasFoundAnyRegistration] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);

  const userInfo = useContext(userInfoContext);

  const accountId = userInfo.accountInfo?.id;

  useEffect(() => {
    (async () => {
      if (!accountId) return;
      setHasLoadedRegistrations(false);
      const token = await getAccessTokenSilently();

      const { data }: { data: FullRegistrationResponse } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v1/registrations/${accountId}?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );

      if (data.count === 0) {
        setHasFoundAnyRegistration(false);
        setHasLoadedRegistrations(true);
        return;
      }

      setRegistrations(
        data.registrations.map((r: RegistrationRowResponse) => ({
          tramitCode: r.registrationCode,
          tramitType: 'registration',
          status: r.status,
          createdAt: r.createdAt,
          incidentType: r.incidentType,
        })),
      );
      setTotalCount(data.count);
      setTotalPages(Math.ceil(data.count / LIMIT));
      setHasLoadedRegistrations(true);
      setHasFoundAnyRegistration(true);
    })();
  }, [params, accountId]);

  function displayRegistrations() {
    if (registrations.length === 0) {
      return <RegistrationsEmptyState showNewRegistrationModal={showModal} />;
    }
    return (
      <>
        <RegistrationsTable tramits={registrations} />
        <Pagination
          limit={LIMIT}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalCount={totalCount}
        />
      </>
    );
  }

  return (
    <div className="flex flex-col gap-5 p-8 w-full h-full min-h-fit bg-gray-100">
      <header className="flex justify-between">
        <h1 className="text-3xl font-semibold">Matriculaciones</h1>
        {hasFoundAnyRegistration && (
          <Button
            text="Nueva matriculación"
            bgColor="bg-blue-600"
            hoverBgColor="bg-blue-700"
            textColor="white"
            callback={showModal}
            disabled={isModalOpen}
          />
        )}
        <NewRegistrationModal isShown={isModalOpen} hide={hideModal} />
      </header>
      {hasLoadedRegistrations ? (
        displayRegistrations()
      ) : (
        <RegistrationsTableSkeletonLoader />
      )}
    </div>
  );
}
