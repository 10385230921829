import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import debounce from 'lodash.debounce';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

import BaseModal from '../../../../../components/BaseModal';
import { useServices } from '../../../../../services';
import { ASYNC_STATE } from '../../../../../helpers/enums';
import { accountDetailsContext } from '../../../../../context/account-details.context';

export default function ChangePricePlanModal({ isOpen, setIsOpen, accountId }) {
  const [pricePlansFound, setPricePlansFound] = useState([]);
  const [selectedPricePlan, setSelectedPricePlan] = useState(null);
  const [text, setText] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [asyncState, setAsyncState] = useState(ASYNC_STATE.INITIAL);

  const { editAccount, searchPricePlansByName } = useServices();

  const { forceUpdate } = useContext(accountDetailsContext);

  const searchPricePlans = useCallback(async (value) => {
    try {
      const pricePlans = await searchPricePlansByName(value);
      setPricePlansFound(pricePlans);
    } catch (e) {
      setErrorMsg('Ocurrió un error al buscar planes de pago con ese nombre');
    }
  }, []);

  const debounced = useMemo(
    () => debounce(searchPricePlans, 500),
    [searchPricePlans],
  );

  const handleReplace = async () => {
    setErrorMsg('');
    setAsyncState(ASYNC_STATE.LOADING);
    try {
      await editAccount(accountId, {
        pricePlanId: selectedPricePlan.id,
      });
      setSelectedPricePlan(null);
      setText('');
      setIsOpen(false);
    } catch (err) {
      setErrorMsg('Ocurrió un error al cambiar el plan de pago');
    } finally {
      setAsyncState(ASYNC_STATE.INITIAL);
      forceUpdate();
    }
  };

  const handleSelectPricePlan = (pricePlan) => {
    setSelectedPricePlan(pricePlan);
    setPricePlansFound([]);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedPricePlan(null);
      setText('');
      setIsOpen(false);
      setPricePlansFound([]);
    }
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} onClose={setIsOpen} overflowVisible>
      <div className="p-8 overflow-y-visible">
        <h1 className="text-2xl font-semibold text-gray-900">
          Cambiar el plan de pago de la empresa
        </h1>
        <div className="mt-6 flex flex-col gap-2 mb-2">
          <div className="flex flex-col">
            <label
              htmlFor="existing"
              className="text-sm font-medium leading-6 text-gray-900"
            >
              Nombre
            </label>
            <input
              name="type"
              type="text"
              className="pl-2 border-gray-300 rounded-md text-gray-800 focus:ring-gray-600 p-0 py-1"
              onChange={(e) => {
                setText(e.target.value);
                debounced(e.target.value);
              }}
              value={text}
            />
          </div>

          <div className="relative">
            {Boolean(pricePlansFound.length) && (
              <div className="flex flex-col items-start bg-white absolute z-50 w-full max-h-[300px] text-left overflow-y-scroll -top-2 rounded-md">
                {pricePlansFound.map((p) => (
                  <button
                    key={p.id}
                    type="button"
                    className="p-4 hover:bg-gray-100 cursor-pointer text-sm text-gray-800 flex gap-x-4 py-3 w-full"
                    onClick={() => handleSelectPricePlan(p)}
                    onKeyDown={() => handleSelectPricePlan(p)}
                  >
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {p.name}
                    </p>
                  </button>
                ))}
              </div>
            )}
          </div>

          {selectedPricePlan && (
            <div className="flex flex-col">
              <span className="font-bold">{selectedPricePlan.name}</span>
              <ul>
                <li>
                  Transferencia:{' '}
                  {selectedPricePlan.transactionPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Batecom - compra (BATE):{' '}
                  {selectedPricePlan.batecomBuyPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Batecom - venta (COM):{' '}
                  {selectedPricePlan.batecomSellPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Matriculación:{' '}
                  {selectedPricePlan.enrollmentPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Duplicado:{' '}
                  {selectedPricePlan.duplicatePrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Duplicado por reforma:{' '}
                  {selectedPricePlan.duplicateDueToModification.toLocaleString(
                    'es-ES',
                    {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'EUR',
                    },
                  )}
                </li>
                <li>
                  Reserva de dominio:{' '}
                  {selectedPricePlan.domainReservationPrice.toLocaleString(
                    'es-ES',
                    {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'EUR',
                    },
                  )}
                </li>
                <li>
                  Gestión carta de pago:{' '}
                  {selectedPricePlan.lienRelease.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Distintivo:{' '}
                  {selectedPricePlan.environmentBadgePrice.toLocaleString(
                    'es-ES',
                    {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'EUR',
                    },
                  )}
                </li>
                <li>
                  Envio:{' '}
                  {selectedPricePlan.shipmentPrice.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Informe DGT:{' '}
                  {selectedPricePlan.trafficReportPrice.toLocaleString(
                    'es-ES',
                    {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'EUR',
                    },
                  )}
                </li>
                <li>
                  Alta:{' '}
                  {selectedPricePlan.vehicleRegistration.toLocaleString(
                    'es-ES',
                    {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'EUR',
                    },
                  )}
                </li>
                <li>
                  Baja:{' '}
                  {selectedPricePlan.vehicleDeregistration.toLocaleString(
                    'es-ES',
                    {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'EUR',
                    },
                  )}
                </li>
                <li>
                  Importación:{' '}
                  {selectedPricePlan.vehicleImport.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Placas rojas:{' '}
                  {selectedPricePlan.redPlates.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Cambio de servicio:{' '}
                  {selectedPricePlan.serviceTypeChange.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Cambio de servicio (aislado):{' '}
                  {selectedPricePlan.isolatedServiceTypeChange.toLocaleString(
                    'es-ES',
                    {
                      minimumFractionDigits: 2,
                      style: 'currency',
                      currency: 'EUR',
                    },
                  )}
                </li>
                <li>
                  Liquidación ITP:{' '}
                  {selectedPricePlan.itpTax.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Liquidación IM:{' '}
                  {selectedPricePlan.IMTax.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  Liquidación IVTM:{' '}
                  {selectedPricePlan.IVTMTax.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </li>
                <li>
                  <span className="flex">
                    Omitir Factura:{' '}
                    {selectedPricePlan.omitInvoice ? (
                      <CheckIcon className="text-teal-500 h-6 ml-1" />
                    ) : (
                      <XIcon className="text-red-500 h-6 ml-1" />
                    )}
                  </span>
                </li>
                <li>
                  <span className="flex">
                    Omitir pago del servicio:{' '}
                    {selectedPricePlan.omitServicePayment ? (
                      <CheckIcon className="text-teal-500 h-6 ml-1" />
                    ) : (
                      <XIcon className="text-red-500 h-6 ml-1" />
                    )}
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center mt-4">
          <button
            type="button"
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 disabled:opacity-50 ${
              selectedPricePlan && asyncState !== ASYNC_STATE.LOADING
                ? 'bg-indigo-600 hover:bg-indigo-500'
                : 'bg-gray-600 hover:bg-gray-500'
            }`}
            onClick={handleReplace}
            disabled={!selectedPricePlan || asyncState === ASYNC_STATE.LOADING}
          >
            Asignar
          </button>
          <p className="text-sm text-red-500">{errorMsg}</p>
        </div>
      </div>
    </BaseModal>
  );
}
